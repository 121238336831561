import Vue from "vue";
import VueRouter from "vue-router";
import { routers } from './router'
Vue.use(VueRouter)


// 路由配置
const RouterConfig = {
  routes: routers,
}

const router = new VueRouter(RouterConfig);

router.afterEach(() => {
  window.scrollTo(0, 0)
})

export default router;
